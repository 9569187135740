import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import { Planning, MapPlanning } from '../../@types/state/planning';
import * as planningServiceApi from './../../services/api/planning.service';
import { PlanningActionTypes } from './types';

export interface GetPlanningsPending
  extends Action<PlanningActionTypes.GET_PLANNINGS_PENDING> {
  pending: boolean;
}

export interface GetPlanningsSuccess
  extends Action<PlanningActionTypes.GET_PLANNINGS_SUCCESS> {
  data: State['planning']['data'];
}

export interface GetPlanningsError
  extends Action<PlanningActionTypes.GET_PLANNINGS_ERROR> {
  message: any;
}

export interface SetCurrentDate
  extends Action<PlanningActionTypes.SET_CURRENT_DATE> {
  currentDate: State['planning']['currentDate'];
}

export interface SetPbxStatus
  extends Action<PlanningActionTypes.SET_PBX_STATUS> {
  status: State['planning']['pxbStatus'];
}

export type PlanningAction =
  | GetPlanningsPending
  | GetPlanningsSuccess
  | GetPlanningsError
  | SetCurrentDate
  | SetPbxStatus;

const getPlanningsPending = (pending: boolean): GetPlanningsPending => {
  return { type: PlanningActionTypes.GET_PLANNINGS_PENDING, pending };
};

const getPlanningsSuccess = (
  data: State['planning']['data']
): GetPlanningsSuccess => {
  return { type: PlanningActionTypes.GET_PLANNINGS_SUCCESS, data };
};

const getPlanningsError = (message: string): GetPlanningsError => {
  return { type: PlanningActionTypes.GET_PLANNINGS_ERROR, message };
};

export const getPlannings = (): ThunkAction<
  Promise<void>,
  State,
  {},
  PlanningAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, PlanningAction>
  ): Promise<void> => {
    dispatch(getPlanningsPending(true));
    try {
      const response = await planningServiceApi.getPlannings();

      if (response.status === 200) {
        const { data } = response;
        const dataNormalized = data.reduce(
          (accumulator: MapPlanning, currentValue: Planning) => {
            accumulator[currentValue['id']] = currentValue;

            return accumulator;
          },
          {}
        );

        dispatch(getPlanningsSuccess({ plannings: dataNormalized }));
      }
    } catch (error) {
      dispatch(getPlanningsError(error.message));
    }
  };
};

export const setCurrentDate = (
  date: State['planning']['currentDate']
): SetCurrentDate => {
  return {
    type: PlanningActionTypes.SET_CURRENT_DATE,
    currentDate: date
  };
};

export const setPbxStatus = (
  status: State['planning']['pxbStatus']
): SetPbxStatus => {
  return {
    type: PlanningActionTypes.SET_PBX_STATUS,
    status
  };
};
