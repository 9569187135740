
const config = [
  {
    key: 'monday',
    label: 'lundi'
  },
  {
    key: 'tuesday',
    label: 'mardi'
  },
  {
    key: 'wednesday',
    label: 'mercredi'
  },
  {
    key: 'thursday',
    label: 'jeudi'
  },
  {
    key: 'friday',
    label: 'vendredi'
  },
  {
    key: 'saturday',
    label: 'samedi'
  },
  {
    key: 'sunday',
    label: 'dimanche'
  }
];

export default config;
