import React from 'react';
import { Role } from '../../../application/roles';
import { Endpoint } from '../../../router/routes.config';
import deploymentImg from '../../../application/images/deploiment_ats_32px.svg';
import fleetImg from '../../../application/images/flotte_studiotel_ATS.svg';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';

import {
  HEADER_TABS_STANDARD,
  HEADER_TABS_STUDIOTEL,
} from '../../../router/routes.config';

export interface TabConfig {
  id: number;
  label: string;
  disabled: boolean;
  Icon: React.ElementType;
  role: Role[];
  tabVisibility: string[];
}

const config = {
  tabs: [
    {
      id: 0,
      label: 'Flotte',
      path: Endpoint.FLEET_SEARCH,
      disabled: false,
      Icon: () => <img src={fleetImg} alt="fleetImg" />,
      role: [Role.ADMIN, Role.DEV],
      tabVisibility: HEADER_TABS_STANDARD,
    },
    {
      id: 1,
      label: 'Déploiements',
      path: Endpoint.DEPLOYMENTS,
      disabled: false,
      Icon: () => <img src={deploymentImg} alt="deploymentImg" />,
      role: [Role.ADMIN, Role.DEV],
      tabVisibility: HEADER_TABS_STANDARD,
    },
    {
      id: 2,
      label: 'Programmation',
      path: Endpoint.STUDIOTEL,
      disabled: true,
      Icon: () => <DateRangeRoundedIcon />,
      role: [Role.ADMIN],
      tabVisibility: HEADER_TABS_STUDIOTEL,
    },
  ],
};

export default config;
