import React from 'react';
import Step1Content, {
  IProps as Step1IProps
} from '../components/workflow/content/step1/step1Content.component';
import Step2Content, {
  IProps as Step2IProps
} from '../components/workflow/content/step2/step2Content.component';
import Step3Content, {
  IProps as Step3IProps
} from '../components/workflow/content/step3/step3Content.component';
import Step4Content, {
  IProps as Step4IProps
} from '../components/workflow/content/step4/step4Content.component';
import Step5Content, {
  IProps as Step5IProps
} from '../components/workflow/content/step5/step5Content.component';
import Step6Content, {
  IProps as Step6IProps
} from '../components/workflow/content/step6/step6Content.component';

export enum Status {
  TO_DEPLOY = 1,
  DEPLOYMENT_IN_PROGRESS = 2,
  DEPLOYMENT_SUCCESS = 3,
  DEPLOYMENT_ERROR = 4
}

export enum StatusDescription {
  TO_DEPLOY = 'Déploiement en préparation',
  DEPLOYMENT_IN_PROGRESS = 'Déploiement en cours',
  DEPLOYMENT_SUCCESS = 'Déployé avec succès',
  DEPLOYMENT_ERROR = 'Erreur de déploiement'
}

export enum StepWorkflow {
  STEP_1 = 0,
  STEP_2 = 1,
  STEP_3 = 2,
  STEP_4 = 3,
  STEP_5 = 4,
  STEP_6 = 5
}

export enum StepWorkflowLabel {
  STEP_1_LABEL = 'Cible',
  STEP_2_LABEL = 'Contenu',
  STEP_3_LABEL = 'Echelle',
  STEP_4_LABEL = 'Plannification',
  STEP_5_LABEL = 'Validation',
  STEP_6_LABEL = 'Confirmation'
}

export interface Step {
  step: number;
  label: string;
  Content: React.ElementType;
}

const config = {
  deployment: {
    inProgressStatus: [
      Status.TO_DEPLOY,
      Status.DEPLOYMENT_IN_PROGRESS,
      Status.DEPLOYMENT_ERROR
    ],
    completedStatus: [Status.DEPLOYMENT_SUCCESS],
    workflow: {
      steps: [
        {
          step: StepWorkflow.STEP_1,
          label: StepWorkflowLabel.STEP_1_LABEL,
          Content: (props: Step1IProps) => <Step1Content {...props} />
        },
        {
          step: StepWorkflow.STEP_2,
          label: StepWorkflowLabel.STEP_2_LABEL,
          Content: (props: Step2IProps) => <Step2Content {...props} />
        },
        {
          step: StepWorkflow.STEP_3,
          label: StepWorkflowLabel.STEP_3_LABEL,
          Content: (props: Step3IProps) => <Step3Content {...props} />
        },
        {
          step: StepWorkflow.STEP_4,
          label: StepWorkflowLabel.STEP_4_LABEL,
          Content: (props: Step4IProps) => <Step4Content {...props} />
        },
        {
          step: StepWorkflow.STEP_5,
          label: StepWorkflowLabel.STEP_5_LABEL,
          Content: (props: Step5IProps) => <Step5Content {...props} />
        },
        {
          step: StepWorkflow.STEP_6,
          label: StepWorkflowLabel.STEP_6_LABEL,
          Content: (props: Step6IProps) => <Step6Content {...props} />
        }
      ]
    }
  }
};

export default config;
