import { push, CallHistoryMethodAction } from 'connected-react-router';
import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import * as authServiceApi from './../../services/api/auth.service';
import { AuthActionTypes } from './types';
import decodeJwt from '../utils/jwt';
// import { Role } from '../../application/roles';
import { Endpoint } from '../../router/routes.config';
// import * as studiotelAction from '../studiotel/studiotel.action';
// import { store } from '../../Store';
// import { selectStudiotels } from '../studiotel/studiotel.selector';
import { waitFor } from '../../services/api/helpers/various.helpers';
import config from '../../application/app.config';

import {
  ValidAskResetPasswordLinkPayload,
  ValidResetPasswordPayload,
  ValidRefreshToken,
  RefreshTokenOptions,
} from '../../@types/auth';

export type AuthAction =
  | CallHistoryMethodAction
  | LoginPending
  | LoginSuccess
  | LoginError
  | LogoutPending
  | LogoutSuccess
  | LogoutError
  | AskResetPasswordLinkPending
  | AskResetPasswordLinkSuccess
  | AskResetPasswordLinkError
  | ResetPasswordPending
  | ResetPasswordSuccess
  | ResetPasswordError
  | RefreshTokenPending
  | RefreshTokenSuccess
  | RefreshTokenError;

export interface LoginPending extends Action<AuthActionTypes.LOGIN_PENDING> {
  pending: boolean;
}
export interface LoginSuccess extends Action<AuthActionTypes.LOGIN_SUCCESS> {
  data: State['auth']['data'];
}
export interface LoginError extends Action<AuthActionTypes.LOGIN_ERROR> {
  message: any;
}

const loginPending = (pending: boolean): LoginPending => {
  return { type: AuthActionTypes.LOGIN_PENDING, pending };
};

const loginSuccess = (data: State['auth']['data']): LoginSuccess => {
  return { type: AuthActionTypes.LOGIN_SUCCESS, data };
};

const loginError = (message: string): LoginError => {
  return { type: AuthActionTypes.LOGIN_ERROR, message };
};

export const login = (
  email: string,
  password: string
): ThunkAction<Promise<void>, State, {}, AuthAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, AuthAction>
  ): Promise<void> => {
    dispatch(loginPending(true));
    try {
      const response = await authServiceApi.login({ email, password });

      await waitFor(config.waitForTime);

      if (response.status === 200) {
        const { data } = response;
        const { token, refresh_token } = data[0];
        if (token) {
          const decodeToken = decodeJwt(token);
          const { role, exp, org } = decodeToken;
          const authData = {
            token: token,
            role: role,
            refreshToken: refresh_token,
            exp: exp,
            organizationId: org,
          };
          localStorage.setItem('auth/studiotel', JSON.stringify(authData));

          await dispatch(loginSuccess(authData));
          dispatch(push(Endpoint.FLEET_SEARCH));
          // TODO: make a rule for USER role : push to studiotel view
          // dispatch(push(`${Endpoint.FLEET}/${authData.organizationId}`))
          // if (
          //   (decodeToken && decodeToken.role === Role.ADMIN) ||
          //   (decodeToken && decodeToken.role === Role.DEV)
          // ) {
          //   dispatch(push(Endpoint.FLEET));
          // } else {
          //   await dispatch(studiotelAction.getStudiotels());
          //   const state = store.getState();
          //   const studiotels = selectStudiotels(state);
          //   let id;
          //   if (studiotels && studiotels.length === 1) {
          //     const studiotel = studiotels[0];
          //     id = studiotel.id;
          //     const path = Endpoint.STUDIOTEL.replace(
          //       /:studiotelId/gi,
          //       id.toString()
          //     );
          //     dispatch(push(path));
          //   }
          //  }
        }
      }
    } catch (error) {
      dispatch(loginError(error.message));
    }
  };
};

export interface LogoutPending extends Action<AuthActionTypes.LOGOUT_PENDING> {
  pending: boolean;
}
export interface LogoutSuccess extends Action<AuthActionTypes.LOGOUT_SUCCESS> {
  data: State['auth']['data'];
}
export interface LogoutError extends Action<AuthActionTypes.LOGOUT_ERROR> {
  message: any;
}

const logoutPending = (pending: boolean): LogoutPending => {
  return { type: AuthActionTypes.LOGOUT_PENDING, pending };
};

const logoutSuccess = (data: State['auth']['data']): LogoutSuccess => {
  return { type: AuthActionTypes.LOGOUT_SUCCESS, data };
};

const logoutError = (message: string): LogoutError => {
  return { type: AuthActionTypes.LOGOUT_ERROR, message };
};

export const logout = (): ThunkAction<Promise<void>, State, {}, AuthAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, AuthAction>
  ): Promise<void> => {
    dispatch(logoutPending(true));
    try {
      dispatch(
        logoutSuccess({
          token: null,
          refreshToken: null,
          role: null,
          organizationId: null,
        })
      );
      localStorage.removeItem('auth/studiotel');
      dispatch(push(Endpoint.LOGIN));
    } catch (error) {
      dispatch(logoutError(error.message));
    }
  };
};

export interface AskResetPasswordLinkPending
  extends Action<AuthActionTypes.ASK_RESET_PASSWORD_LINK_PENDING> {
  pending: boolean;
}

export interface AskResetPasswordLinkSuccess
  extends Action<AuthActionTypes.ASK_RESET_PASSWORD_LINK_SUCCESS> { }

export interface AskResetPasswordLinkError
  extends Action<AuthActionTypes.ASK_RESET_PASSWORD_LINK_ERROR> {
  message: any;
}

const askResetPasswordLinkPending = (
  pending: boolean
): AskResetPasswordLinkPending => {
  return { type: AuthActionTypes.ASK_RESET_PASSWORD_LINK_PENDING, pending };
};

const askResetPasswordLinkSuccess = (): AskResetPasswordLinkSuccess => {
  return { type: AuthActionTypes.ASK_RESET_PASSWORD_LINK_SUCCESS };
};

const askResetPasswordLinkError = (
  message: string
): AskResetPasswordLinkError => {
  return { type: AuthActionTypes.ASK_RESET_PASSWORD_LINK_ERROR, message };
};

export const askResetPasswordLink = (
  login: ValidAskResetPasswordLinkPayload
): ThunkAction<Promise<void>, State, {}, AuthAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, AuthAction>
  ): Promise<void> => {
    dispatch(askResetPasswordLinkPending(true));
    try {
      const response = await authServiceApi.askResetPasswordLink(login);

      await waitFor(config.waitForTime);

      if (response.status === 204) {
        if (response) {
          dispatch(askResetPasswordLinkSuccess());
        }
      }
    } catch (error) {
      dispatch(askResetPasswordLinkError(error.message));
    }
  };
};

export interface ResetPasswordPending
  extends Action<AuthActionTypes.RESET_PASSWORD_PENDING> {
  pending: boolean;
}

export interface ResetPasswordSuccess
  extends Action<AuthActionTypes.RESET_PASSWORD_SUCCESS> { }

export interface ResetPasswordError
  extends Action<AuthActionTypes.RESET_PASSWORD_ERROR> {
  message: any;
}

const resetPasswordPending = (pending: boolean): ResetPasswordPending => {
  return { type: AuthActionTypes.RESET_PASSWORD_PENDING, pending };
};

const resetPasswordSuccess = (): ResetPasswordSuccess => {
  return { type: AuthActionTypes.RESET_PASSWORD_SUCCESS };
};

const resetPasswordError = (message: string): ResetPasswordError => {
  return { type: AuthActionTypes.RESET_PASSWORD_ERROR, message };
};

export const resetPassword = (
  password: ValidResetPasswordPayload
): ThunkAction<Promise<void>, State, {}, AuthAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, AuthAction>
  ): Promise<void> => {
    dispatch(resetPasswordPending(true));
    try {
      const response = await authServiceApi.resetPassword(password);

      await waitFor(config.waitForTime);

      if (response.status === 204) {
        if (response) {
          dispatch(resetPasswordSuccess());
          dispatch(logout());
        }
      }
    } catch (error) {
      dispatch(resetPasswordError(error.message));
    }
  };
};

export interface RefreshTokenPending
  extends Action<AuthActionTypes.REFRESH_TOKEN_PENDING> {
  pending: boolean;
}

export interface RefreshTokenSuccess
  extends Action<AuthActionTypes.REFRESH_TOKEN_SUCCESS> {
  data: State['auth']['data'];
}

export interface RefreshTokenError
  extends Action<AuthActionTypes.REFRESH_TOKEN_ERROR> {
  message: any;
}

const refreshTokenPending = (pending: boolean): RefreshTokenPending => {
  return { type: AuthActionTypes.REFRESH_TOKEN_PENDING, pending };
};

const refreshTokenSuccess = (
  data: State['auth']['data']
): RefreshTokenSuccess => {
  return { type: AuthActionTypes.REFRESH_TOKEN_SUCCESS, data };
};

const refreshTokenError = (message: string): RefreshTokenError => {
  return { type: AuthActionTypes.REFRESH_TOKEN_ERROR, message };
};

export const refreshToken = (
  refreshToken: ValidRefreshToken,
  option?: RefreshTokenOptions
): ThunkAction<Promise<void>, State, {}, AuthAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, AuthAction>
  ): Promise<void> => {
    dispatch(refreshTokenPending(true));
    try {
      const response = await authServiceApi.refreshToken(refreshToken);

      await waitFor(config.waitForTime);

      if (response.status === 200) {
        const { token, refresh_token } = response.data;
        if (token) {
          const decodeToken = decodeJwt(token);
          const { role, exp, org } = decodeToken;
          const authData = {
            token: token,
            role: role,
            refreshToken: refresh_token,
            exp: exp,
            organizationId: org,
          };
          localStorage.setItem('auth/studiotel', JSON.stringify(authData));

          await dispatch(refreshTokenSuccess(authData));

          // reset password options push to change password view
          if (option && option.resetPassword) {
            dispatch(push(Endpoint.CHANGE_PASSWORD));
          }

          // auth with refresh token option push to the line search view
          if (option && option.authWithRefreshToken) {
            // dispatch(push(Endpoint.LINE_SEARCH));
          }
        }
      }
    } catch (error) {
      dispatch(refreshTokenError(error.message));
    }
  };
};
